@import "variables";

$form-select-indicator-color:       $white;

$light:                             $white;
$primary:                           $black;

$form-check-input-border-radius: 0;

$form-check-input-width:                  1.5em;
$form-check-min-height:                   1.5em;

$form-check-input-bg:                     transparent;
$form-check-input-border:                 1px $white solid;

$btn-border-radius:                        0;

.btn{
  &.btn-primary{
    &:hover{
      background-color: $fm-red;
    }
  }
}