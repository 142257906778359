@import "normalize";
@import "bootstrap_variables";
@import "~bootstrap/scss/bootstrap";
@import "variables";

body,
div,
span,
input,
label,
textarea,
select,
option,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: $elc-design-font-base-text;
}

html,
body,
#root {
  @media (max-width: 1279px) {
    height: 100%;

    main {
      height: calc(100% - 120px);

      .home-main-section {
        height: calc(100% - 34px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;

        &.page-flow {
          align-items: flex-start;
          margin-top: 1rem;
          &.flow-stop {
            position: relative;
            .ada-info-icon {
              top: 20px;
              right: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    main {
      height: 100%;

      .home-main-section {
        overflow: auto;
        height: calc(100% - 34px);
      }
    }
  }

  @media (max-height: 520px) {
    main {
      height: 100%;

      .home-main-section {
        overflow: auto;
        align-items: flex-start;
        height: 100%;
      }
    }
  }

  @media (min-width: 1280px) {
    height: 100%;
    min-height: 100%;

    main {
      height: 100%;
      min-height: 100%;
    }
  }
}

html, body {
  overflow: hidden;
  -webkit-overflow-scrolling: unset;
}

#root{
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: unset;
  
}

.visually-hidden {
  display: none;
}

ul {
  list-style-type: none;
}

body {
  background-color: $black;
  overflow-x: hidden;
}

.fm-app {
  color: $white;
  background-color: $black;
  padding: 0;
  margin: 0;
  min-height: 100%;
  padding-top: 132px;
  &.bg-black{
    background-color: $black;
  }
  &.bg-red{
    background-color: $fm-red;
    .fm-app-wrapper {
      .wizard-wrapper {
        .image-grid-wrapper {
          ul {
            li {
              input {
                &:checked {
                  + .selection-item {
                    &:after{
                      border-color: $black;
                    }
                    span {
                      &:after {
                        background-color: $black;
                      }
                    }      
                  }
                }
              }
            }
          }
        }
      }
    }  
  }
  .fm-nav {
    position: absolute;
    top: 40px;
    left: 30px;
    select {
      color: #fff;
      background-color: #000;
      border: 0 none;
      text-transform: uppercase;
      padding-right: 1.75rem;
      option {
        color: initial;
      }
    }
  }
  .fm-header-wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    flex-flow: column;
    align-items: center;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    height: 80px;
    z-index: 15;
    background-color: $black;
    .fm-header {
      width: 180px;
      height: 32px;
      margin-top: 39px;
    }
    @media (max-width: 767px) {
      .fm-header {
        align-self: flex-start;
        margin-left: 2rem;
        margin-top: 1rem;
      }
      .fm-nav{
        display: none;
      }
    }
    @media (min-width: 768px) {
      height: 100px;
      .fm-header {
        margin-top: 0;
      }
    }
     @media (min-width: 1440px) {
      height: 132px;
    }
  }
  .fm-app-wrapper {
    min-height: 100%;
    align-items: center;
    align-content: center;
    .fm-container {
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 768px;
      }
    }
    .wizard-landing-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      .wizard-landing-content {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .fm-gender-selection {
          margin-bottom: 56px;
          .btn {
            min-width: 206px;
            border-radius: 0;
            margin: 0 8px;
            padding: 16px;
            &:hover {
              background-color: $fm-red;
              color: $white;
              border-color: $fm-red;
            }
          }
          @media (max-width: 1023px) {
            width: 100%;
            .btn-group{
              display: flex;
              flex-flow: column wrap;  
              align-items: center;
            
              label {
                margin-bottom: 16px;
                max-width: 240px;
              }
            }
          }
        }
        .fm-privacy-disclaimer {
          width: 650px;
          .disclaimer-action-block {
            display: flex;
            justify-content: center;
            flex-flow: row;
            text-align: center;
            margin-bottom: 2rem;
            label {
              font-size: 0.75em;
              margin-left: 12px;
            }
          }
          @media (max-width: 1023px) {
            width: auto;
          }
        }
      }
      h2 {
        width: 80%;
        text-align: center;
        font-size: 72px;
        margin-bottom: 0;
        span {
          color: $fm-red;
        }
        @media (max-width: 767px) {
          font-size: 32px;
        }
      }
      h3 {
        font-size: 36px;
        margin-top: 80px;
        margin-bottom: 48px;
        @media (max-width: 767px) {
          font-size: 24px;
          margin-top: 53px;
          margin-bottom: 24px;  
        }
      }
    }
    .wizard-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      .wizard-selections {
        margin-bottom: 230px;
      }
      h2 {
        margin: 48px 0;
        text-align: center;
      }
      .image-grid-wrapper {
        width: 100%;
        .btn-group {
          width: 100%;
        }
        ul {
          width: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          flex-flow: row wrap;
          li {
            text-align: center;
            width: 108px;
            height: 108px;
            padding: 8px;
            input:checked {
              + .selection-item {
                position: relative;
                &:after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  border: 4px $fm-red solid;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                span {
                  &:after {
                    content: "\f26b";
                    font-family: "Material-Design-Iconic-Font";
                    font-size: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    background-color: $fm-red;
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }
              }
            }
            .selection-item {
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 0;
              padding: 0;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          @media (min-width: 768px) {
            li{
              width: 176px;
              height: 176px;
              padding: 8px;
            }
          }
          @media (min-width: 1024px) {
            li{
              width: 196px;
              height: 196px;
              padding: 8px;
            }
          }
          @media (min-width: 1440px) {
            li{
              width: 218px;
              height: 218px;
              padding: 8px;
            }
          }
        }
      }
      .controls-wrapper {
        position: fixed;
        width: 100%;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .wizard-steps-control {
          background-color: $white;
          width: 336px;
          height: 96px;
          padding: 24px 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          h3 {
            font-size: 16px;
            line-height: 20px;
            color: $black;
            margin: 0;
            vertical-align: middle;
            display: flex;
            align-items: center;
            .sub-header{
              color: $fm-grey-1;
              font-weight: 300;
              font-size: 14px;
              margin-top: .5rem;
            }
          }
          ul {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;

            li {
              button {
                width: 48px;
                height: 48px;
                font-size: 22px;
              }
              + li {
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
    .results-wrapper {
      background: white;
      padding: 0;
      .promo {
        width: auto;
        display: flex;
        flex-direction: column;
        font-family: $elc-design-font-base-text;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 1.5rem;
        position: relative;
        @media (max-width: 767px){
          flex-direction: column-reverse;
          overflow: hidden;
        }
        @media (min-width: 768px) {
          padding: 0;
          flex-direction: row;
          align-items: center;
        }
        .promo-mobile{
          display: block;
          padding: 12px 0px;
          @media (min-width: 768px){
            display: none;
            padding: 0px;
          }
        }
        .promo-header {
          width: 100%;
          height: auto;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: $black;
          @media (min-width: 768px) {
            text-align: left;
            font-size: 32px;
            line-height: 36px;
          }
          @media (min-width: 1440px) {
            text-align: left;
            font-size: 36px;
            line-height: 40px;
          }
        }
        .promo-left {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          @media (min-width: 768px) {
            width: 50%;
            align-items: center;
            padding: 96px 71px;
          }
          @media (min-width: 1024px) {
            width: 35%;
            padding: 64px 64px 75px 64px;
          }
          @media (min-width: 1440px) {
            padding: 10rem;
          }
        }
        .promo-left-inside {
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-top: auto;
          padding-bottom: 12px;
          @media (max-width: 767px){
            .promo-header{
              display: none;
            }
          }
          @media (min-width: 768px) {
            gap: 1rem;
          }
        }
        .promo-right {
          width: 100%;
          @media (max-width: 767px) {
            overflow: hidden;
          }
          @media (min-width: 768px) {
            width: 50%;
            overflow: hidden;
          }
          @media (min-width: 1024px) {
            width: 65%;
          }
          .promo-img {
            width: 100%;
            @media (max-width: 767px) {
              height: 280px;
              width: auto;
            }
            @media (min-width: 768px) {
              width: auto;
              height: 352px;
              position: relative;
              top: -32px;
              left: -50%;
            }
            @media (min-width: 1024px) {
              width: 100%;
              height: auto;
              left: unset;
            }
            @media (min-width: 1440px) {
              width: 822px;
              height: auto;
              position: relative;
              top: 0px;
            }
          }
          @media (max-width: 1023px) {
            &.samples-request{
              .promo-img{
                height: auto;
                top: 0;
              }
            }
          }
        }
        .promo-btn {
          width: 100%;
          height: 48px;
          padding: 16px 24px 16px 24px;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 767px) {
            margin: 1.5rem 0px;
          }
          @media (min-width: 768px) {
            width: 154px;
            height: 56px;
            text-align: center;
          }
        }
      }
      .perfume-recs{
        .swiper {
          width: 100%;
          height: 100%;
          margin-bottom: 1rem;
        }
        .swiper-wrapper{
          @media (min-width: 1024px){
            justify-content: center;
          }
        }
        .swiper-button-next,.swiper-button-prev {
          width: 48px;
          height: 48px;
          font-size: 24px;
          padding: 10px;
          background-color: $white;
          color: black;
          top: 36vw;
          border-style: solid;
          border-color: #E7E6E2;
          @media (min-width: 768px){
            border: 0px;
            top:35%;
          }
        }
        .swiper-button-next{
          right: -.5rem;
          &:after{
            font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
            font-size: inherit;
            content: "\f2ee";
          }
          @media (min-width: 768px){
            right: .5rem;
          }
        }
        .swiper-button-prev{
          left:-.5rem;
          &:after{
            font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
            font-size: inherit;
            content: "\f2ea";
          }
          @media (min-width: 768px){
            left: .5rem;
          }
        }
        .swiper-slide {
          display: flex;
          justify-content: stretch;
          align-items: center;
          flex-direction: column;
          position: relative;
          height: auto;
          @media(min-width: 768px){
            width: 50%;
          }
          @media (min-width: 1024px){
            width: 33%;
          }
          .perfume-recs-button{
            margin-top: auto;
            width: 100%;
            div{
              padding: 0 50px;
            }
          }
        }
        .perfume-recs-bottom{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          padding-bottom: 8px;
          @media (min-width: 768px) {
            align-items: center;
          }
        }
        .perfume-recs-top{
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
          overflow: hidden;
          img{
            cursor: pointer;
            width: 120%;
            height: auto;
            margin-left: -10%;
            margin-top: -13%;
          }
          .perfume-recs-hover-img{
            position: relative;
            top: -107%;
            opacity: 0;
            transition:0.35s ease;
          }
          &:hover{
            .perfume-recs-hover-img{
              opacity: 1;
            }
          }
        }
        .perfume-recs-text{
          width: 100%;
          padding: 20px 50px 33px 50px;
          color: $black;
          @media(min-width: 768px){
            padding: 20px 50px 0px 50px;
          }
          h3{
            font-family: $elc-design-font-base-text;
            letter-spacing: 0em;
            color: $black;
            text-align: left;
          }
          .perfume-title{
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: .125rem;
          }
          .perfume-artist{
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            padding-bottom: 5px;
          }
          .perfume-description{
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #767676;
          }
        }
        .perfume-recs-header{
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          color: $black;
          text-align: center;
          padding: 2rem 0px;
          margin-bottom: 0;
          @media(min-width: 768px){
            font-size: 32px;
            font-weight: 400;
            line-height: 36px;
          }
          @media(min-width: 1440){
            font-size: 36px;
            line-height: 40px;
          }
        }
        .perfume-recs-btn {
          width: 100%;
          height: 56px;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-style: solid;
          border-color: #D1D1D1;
          color: $black;
          background-color: #ffffff;
          &:hover{
            background-color: $black;
            color: $white;
          }
          @media (min-width: 768px) {
            max-width: 100%;
          }
        }
      }
      .email-results-wrapper{
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          margin-top: 24px;
        }
        @media (min-width: 768px) {
          justify-content: end;
        }
        @media (min-width: 1280px) {
          top: -95px;
        }
      }
      .results-hero{
        background-color: $black;
      }
      .vertical-position-adjust{
        .email-results-wrapper{
          @media (max-width: 1279px) {
            top: -95px;
          }
          @media (max-width: 991px) {
            top: auto;
          }
        }
        @media (max-width: 1439px) {
          max-height: 24px;
        }
        @media (max-width: 1279px) {
          max-height: 24px;
        }
        @media (max-width: 991px) {
          max-height: 100%;
        }
      }
    }
  }
  .fm-footer{
    min-height: 150px;
    position: static;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-top: 4rem;
    .navbar{
      width: 100%;
    }
    .fm-footer-nav{
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    .nav-item{
      width: 25%;
      text-align: center;
      &.x2{
        width: 50%;
      }
      a{
        color: $white;
        text-decoration: none;
      }
    }
    @media (min-height: 1164px) {
      position: fixed;
      min-height: 400px;
    }
  }
  &.fm-wizard{
    .fm-footer{
      display: none;
    }
  }
  &.fm-results{
    .fm-footer{
      position: static;
      min-height: 0;
      margin-bottom: 4rem;
      @media (min-height: 1164px) {
        position: static;
      }
    }
  }
  @media (max-width: 1279px) {
    padding-top: 100px;
  }
  @media (max-width: 991px) {
    padding-top: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 100px;
  }
}

.auth-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;

  img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;

    @media (min-width: 1280px) {
      top: 0;
      right: 0;
    }
  }

  .x-header {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 115px;
    height: 88px;
    z-index: 4;

    @media (min-width: 720px) {
      left: 48px;
      top: 48px;
    }

    @media (min-width: 1440px) {
      left: 108px;
      right: auto;
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;

    .footer-wrapper {
      display: flex;
      flex-flow: row;
      width: 100%;
      padding: 48px;

      @media (max-width: 767px) {
        flex-flow: column;
      }

      @media (min-width: 1440px) {
        padding: 48px 108px;
      }

      > p,
      > ul {
        width: 50%;

        @media (max-width: 767px) {
          width: 100%;
          display: block;
        }
      }

      p,
      ul li,
      ul li a {
        color: #fff;
      }

      p {
        @media (max-width: 767px) {
          margin: 0;
          padding: 10px 0;
        }
      }

      ul {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 767px) {
          display: block;
          padding: 0;
          margin: 0;
        }

        li {
          min-width: 120px;
          text-align: right;

          @media (max-width: 767px) {
            min-width: 0;
            width: 100%;
            text-align: left;
            display: block;
            padding: 10px 0;
          }

          a {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 3;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .login-panel {
    width: 100%;
    background-color: #fff;
    min-height: 360px;
    border-radius: 16px;
    padding: 64px;
    text-align: left;
    z-index: 2;

    h3 {
      font-size: 32px;
      line-height: 40px;

      a {
        color: #212426;
        text-decoration: none;
      }
    }

    input {
      border: 0 none;
      border-bottom: 1px #212426 solid;
      width: 100%;
      display: block;
      min-height: 56px;
      margin-bottom: 40px;
      padding: 12px;
    }

    .auth-fields {
      width: 100%;
      position: relative;

      label {
        position: absolute;
        bottom: -18px;
        right: 0;
        font-size: 12px;
        color: #f00;
      }
    }

    .auth-controls {
      display: flex;
      flex-flow: row;
      align-items: center;
      width: 100%;

      a {
        color: #212426;
        text-decoration: underline;
      }

      button {
        min-width: 40%;
        min-height: 56px;
        margin-right: 24px;
        background-color: #666;
        border: 0 none;
        color: #fff;

        &:hover {
          background-color: #041c2c;
        }
      }

      @media (max-width: 767px) {
        flex-flow: column;

        button,
        a {
          margin: 0;
          margin-bottom: 12px;
          width: 100%;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 24px;
    }

    @media (min-width: 520px) {
      width: 75%;
    }

    @media (min-width: 720px) {
      width: 60%;
    }

    @media (min-width: 1024px) {
      width: 50%;
    }

    @media (min-width: 1280px) {
      width: 40%;
    }

    @media (min-width: 1440px) {
      width: 30%;
    }
  }
}

.gauge-container > .gauge .value {
  stroke: #2ad885;
  stroke-width: 8;
}

.gauge-container .text-container .value-text {
  font-size: 20px;
  font-weight: 600;
  fill: #000;
}

.breadcrumb-list {
  padding: 0;
  margin-bottom: 64px;

  a {
    color: #212426;
    text-decoration: none;
  }

  li {
    display: inline-block;
    margin: 0 0 0 8px;
    border-left: 2px #212426 solid;
    padding: 0 0 0 8px;
    font-size: 14px;

    &:first-child {
      margin-left: 0;
      padding-left: 0;
      border-left: 0 none;
    }

    &.active {
      color: #858d95;
    }
  }
}

.embed-actions {
  padding-bottom: 1rem;

  a {
    color: #212426;
    text-decoration: none;
  }
}

.stats-social-link {
  padding-left: 48px;
  margin-bottom: 48px;

  a {
    font-size: 14px;
    line-height: 24px;
    color: #858d95;
  }
}

.email-slideout {
  width: 277px;
}

.email-slideout-form {
  @media (min-width: 992px) {
    width: 800px;
  }

  .privacy-tooltip {

    &.bs-tooltip-top .tooltip-arrow:before {
      border-top-color: #ccc;
      top: 1px;
    }

    &.bs-tooltip-end .tooltip-arrow:before {
      border-right-color: #ccc;
      right: 0px;
    }
  }
  
  .fm-checkbox-label {
    color: $fm-grey-1;
    font-size: 0.8rem;
  }

  .placeholder-fm {
    border-color: #D1D1D1;
    border-style: solid;
    border-width: 1px;
  }

  .placeholder-fm::placeholder {
    color: $fm-grey-3;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .fm-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
    
}
.results-display {
  position: relative;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 1279px) {
    padding: 0px;
  }
  @media (max-width: 991px) {
    padding: 10px;
  }
}

.quiz-title {
  margin-top: 0;
  text-align: center;
  font-family: $elc-design-font-base-text;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.quiz-start-over-button-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: -25px;
  @media (max-width: 767px) {
    display: none;
  }
}

.quiz-start-over-button {
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-family: $elc-design-font-base-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.refresh-button {
  width: 16.003px;
  height: 16.003px;
  flex-shrink: 0;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
}

.image-grid-results { 
  width: 100%;
  height: 456px; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px; 
  margin-top: 40px;
  @media (max-width: 1279px) {
    margin-top: 10px;
  }
  @media (max-width: 992px) {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    width: 534px;
  }
}

.image-item {
  transform-origin: center center;
  overflow: hidden;
  img {
    height: auto;
    width: 100%; 
    }
}

.image-item.accessories {
  transform: rotate(-12.133deg);
  position: absolute;
  width: 120px; 
  height: 120px; 
  bottom: 100px;
  left: -50px;
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    bottom: 56px;
    left: 32px;
  }
  &.item-2{
    transform: rotate(15deg);
    left: 60px;
    bottom: 60px;
  }
  &.item-3{
    transform: rotate(-5deg);
    left: 210px;
    bottom: 55px;
  }
}

.image-item.environment {
   width: 80%;
   height: 345px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 534px;
  }
  @media (max-width: 767px) {
    width: 534px;
  }
}

.image-item.footwear {
  transform: rotate(8deg);
  position: absolute;
  right: 0px;
  top: 30px;
  width: 120px;
  height: 120px;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    right: -15px;
  }
  &.item-2{
    top: 130px;
    z-index: 1;
    transform: rotate(-8deg);
    right: -40px;
  }
}

.image-item.hair {
  transform: rotate(5deg);
  position: absolute;
  z-index: 2;
  top: 80px;
  left: 260px;
  width: 120px;
  height: 120px;
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    left: 130px;
  }
}

.image-item.looks {
  transform: rotate(9.102deg);
  position: absolute;
  left: -60px;
  top: 75px;
  width: 120px;
  height: 120px;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    left: -25px;
    top: 100px;
  }
  &.item-2{
    top: 0px;
    z-index: 1;
    transform: rotate(-2deg);
    left: 5px;
  }
}

.image-item.transport {
  width: 242px;
  height: 237px;
  position: absolute;
  right: -50px;
  bottom: 10px;
  @media only screen and (max-width: 767px) {
    right: -20px;
    bottom: 10px;
  }
}

.image-item.underwear {
  transform: rotate(-6deg);
  position: absolute;
  top: 40px;
  right: 300px;
  width: 120px;
  height: 120px;
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    top: 56px;
    left: 80px;
  }
}

.slogan {
  text-align: center;
  margin-top: 50px; 
  @media (max-width: 1279px) {
    margin-top: 0px;
  }
  @media (max-width: 991px) {
    margin-top: 0px;
  }
}

.slogan-line-one {
  color: #fff;
  font-family: $elc-design-font-base-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: uppercase;
}

.slogan-line-two {
  color: #fff;
  font-family: Times;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.64px;
  margin-top: 5px; 
} 

.w-unset {
  width: unset;
}

.pf-offcanvas-height{
  height: 297px !important;
}

.image-row{
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.no-selections-page {

  .bottom-sticky-slogan {
    position: absolute;
    bottom: 50px;
    width: 100%;
  }

  * > .button-light {
    height: 56px;
    width: 154px;
  }

  * > .image-row {

    img {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  @media only screen and (min-width: 1440px) {

    * > .button-light {
      margin-top: 40px;
    }  

    * > .image-row {

      margin-top: 80px;
      margin-bottom: 80px;
    }  
  }

  @media only screen and (max-width: 1439px) {

    * > .button-light {
      margin-top: 32px;
    }  

    * > .image-row {

      margin-top: 56px;
      margin-bottom: 56px;
    }  
  }

  @media only screen and (max-width: 1023px) {

    * > .button-light {
      margin-top: 96px;
    }  

    * > .image-row {

      margin-top: 164px;
      margin-bottom: 164px;
    }  
  }

  @media only screen and (max-width: 767px) {

    * > .button-light {
      margin-top: 64px;
    }  

    * > .image-row {

      margin-top: 110px;
      margin-bottom: 110px;

      img {
        margin-right: 4px;
        margin-left: 4px;
      }
    }  
  }
}

.privacy-policy-link {
  color: white;
  text-decoration: underline;
}

.text-no-margin-padding {
  margin-left: 0;
  padding-left: 0;
}

.tooltip-inner {
  padding: 20px;
  text-align: left;
  background-color: white;
  color: black;
  opacity: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  min-width: 400px;

  @media (max-width: 767px) {
    min-width: 100px;
  }
}

.email-error-message {
  font-size: 12px;
  padding-top: 6px;
}